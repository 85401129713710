// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-generate-category-one-js": () => import("./../../../src/generate/categoryOne.js" /* webpackChunkName: "component---src-generate-category-one-js" */),
  "component---src-generate-category-two-js": () => import("./../../../src/generate/categoryTwo.js" /* webpackChunkName: "component---src-generate-category-two-js" */),
  "component---src-generate-detail-faq-js": () => import("./../../../src/generate/detailFaq.js" /* webpackChunkName: "component---src-generate-detail-faq-js" */),
  "component---src-generate-role-one-js": () => import("./../../../src/generate/roleOne.js" /* webpackChunkName: "component---src-generate-role-one-js" */),
  "component---src-generate-role-two-manager-js": () => import("./../../../src/generate/roleTwoManager.js" /* webpackChunkName: "component---src-generate-role-two-manager-js" */),
  "component---src-generate-role-two-nyuko-js": () => import("./../../../src/generate/roleTwoNyuko.js" /* webpackChunkName: "component---src-generate-role-two-nyuko-js" */),
  "component---src-generate-role-two-owner-js": () => import("./../../../src/generate/roleTwoOwner.js" /* webpackChunkName: "component---src-generate-role-two-owner-js" */),
  "component---src-generate-role-two-sub-owner-js": () => import("./../../../src/generate/roleTwoSubOwner.js" /* webpackChunkName: "component---src-generate-role-two-sub-owner-js" */),
  "component---src-generate-role-two-user-js": () => import("./../../../src/generate/roleTwoUser.js" /* webpackChunkName: "component---src-generate-role-two-user-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

