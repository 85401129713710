if (document.documentMode && navigator.msSaveOrOpenBlob) {
  window.addEventListener('click', function (eve) {
    var a = eve.target
    if (!a.hasAttribute('download')) return
    eve.preventDefault()
    var filename = a.getAttribute('download')
    var xhr = new XMLHttpRequest()
    xhr.open('GET', a.href)
    xhr.responseType = 'blob'
    xhr.send()
    xhr.onload = function () {
      navigator.msSaveOrOpenBlob(xhr.response, filename)
    }
  })
}
